/* eslint-disable ngrx/avoid-mapping-selectors */
import { Location } from '@angular/common';
import {
	ChangeDetectorRef,
	EventEmitter
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import {
	combineLatestWith,
	filter,
	map,
	Observable,
	of,
	Subscription,
	take,
	takeUntil,
	tap
} from 'rxjs';
import { EnvironmentService } from '../../../environments/environment.service';
import { LogService } from '../../core/log/log.service';
import { MetaService } from '../../core/meta/meta.service';
import {
	clearCurrentContent,
	clearTemporaryMedia,
	insertComment,
	insertMediaAndComment,
	loadComments,
	loadContent,
	uploadTemporaryMedia
} from '../../domain/content/content.actions';
import { Content, ContentQueryParams, DNComment } from '../../domain/content/content.model';
import {
	selectCurrentContent,
	selectCurrentContentComments,
	selectCurrentError,
	selectPremiumCurrentContent,
	selectTemporaryMediaName
} from '../../domain/content/content.selectors';
import { ErrorMessage } from '../../domain/error/error-message.model';
import {
	getTemplateFromId,
	getThreadTemplate,
	TemplateTypeId
} from '../../domain/template-ct/template-ct.model';
import {
	selectContentTemplate,
	selectTemplateByID
} from '../../domain/template-ct/template-ct.selectors';
import { selectUser, selectUserId } from '../../domain/user/user.selectors';
import { CreateCommentDTO } from '../../shared/comments/comments.component';
import { loadDialog } from '../../shared/dialog/dn-dialog.actions';
// import { FileReaderDialogComponent } from '../../shared/dialog/file-reader-dialog/file-reader-dialog.component';
import { ImageViewerDialogComponent } from '../../shared/dialog/image-viewer-dialog/image-viewer-dialog.component';
import { VideoPlayerDialogComponent } from '../../shared/dialog/video-player-dialog/video-player-dialog-component';
import { isContentPage } from '../../shared/util/util';
import {
	checkCodiceSconto,
	clearErrorToken,
	confirmCardPaymentAction,
	confirmSubscriptionSuccess,
	intentSubscriptionAction,
	loadDiscountType,
	loadPremiumModules,
	loadUserPremiumAction,
	updateSubscription
} from '../../domain/extra/extra.actions';
import {
  CompletePaymentIntent,
	CouponState,
	MyPremiumModuleElement,
	MyPremiumState,
	PaymentConfirmation,
	PremiumDiscountType,
	PremiumModuleElement,
	PremiumSubscription,
	PremiumTokenError
} from '../../domain/extra/extra.model';
import { UserDTO } from '../../domain/user/user.model';
import {
	selectAllDiscountType,
	selectAllPremiumModules,
	selectMyErrorToken,
	selectMyPremium,
	selectPaymentIntent,
	selectPaymentSuccess,
	selectPremiumCouponState
} from '../../domain/extra/extra.selectors';
import { NotificationService } from '../../core/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { clearCurrentTopic } from '../../domain/topic/topic.actions';
import { selectSessionID } from '../../core/session/session.selectors';
import { clearPoll } from '../../domain/poll-smart/poll-smart.actions';

export abstract class ContentContainerComponent  {
	// Which container are we gonna build?
	templateType: TemplateTypeId = undefined;

	// Actual content
	currentContent$: Observable<Content> = new Observable();

	// Content comments
	comments$: Observable<DNComment[]> = new Observable();

	// Error
	contentError$: Observable<ErrorMessage> = new Observable();
	userId: number;
	contentError: ErrorMessage;

	// extra premium Observable
  paymentIntent$: Observable<CompletePaymentIntent> = new Observable<CompletePaymentIntent>();
	paymentSuccess$: Observable<boolean> = new Observable<boolean>();
  sessionId$: Observable<number> = new Observable<number>();
	premiumModules$: Observable<PremiumModuleElement[]> = new Observable();
  payloadPremiumModules: { forcedCampaigns: string; userId: number };
	tokenError$: Observable<PremiumTokenError> = new Observable();
	myPremium$: Observable<MyPremiumState> = new Observable();
	coupon$: Observable<CouponState> = new Observable();
	discountTypeList$: Observable<PremiumDiscountType[]> = new Observable();
	user$: Observable<UserDTO> = new Observable();
	contentId: number;
	title_message: string;
	submitUpdateSubscription: EventEmitter<MyPremiumModuleElement> =
		new EventEmitter<MyPremiumModuleElement>();
	submitCheckTokenForm: EventEmitter<string> = new EventEmitter<string>();
	submitSubscription: EventEmitter<PremiumSubscription> = new EventEmitter<PremiumSubscription>();
	submitLoadUserPremium: EventEmitter<number> = new EventEmitter<number>();
	submitCouponGeneration: EventEmitter<{
		idTipoSconto: number;
		idAnagrafica: number;
	}> = new EventEmitter<{ idTipoSconto: number; idAnagrafica: number }>();

	// Sub list
	subList: Subscription[] = [];
	shareLink: string;
	noBanner: boolean = false;

	constructor(
		public store: Store,
		public actRoute: ActivatedRoute,
		public envService: EnvironmentService,
		public router: Router,
		public logService: LogService,
		public location: Location,
		templateType: TemplateTypeId,
		public notificationService: NotificationService,
		public metaService: MetaService,
		public translate: TranslateService,
		public changeDetector: ChangeDetectorRef
	) {
		this.templateType = templateType;
	}

	init(): void {
		this.noBanner = this.analyzeQueryParams(this.router.url, ContentQueryParams.NOBANNER);

   	this.currentContent$ = this.store.select(selectCurrentContent).pipe(
			filter((content) => !!content),
			tap((content) => this.location.replaceState(content.permalink)),
			// sets title for direct contents, like articolo, file, video
			tap((content: Content) => this.metaService.setMeta(content)),
			map((content) => {
        this.shareLink = content.permalink;
        return { ...content, url: this.envService.contentPath + content.url }
      })
		);
		this.contentError$ = this.getContentError();

		this.comments$ = this.store.select(selectCurrentContentComments);

		// Observable for extra premium form
		this.premiumModules$ = this.store.select(selectAllPremiumModules);
		this.discountTypeList$ = this.store.select(selectAllDiscountType);
		this.tokenError$ = this.store.select(selectMyErrorToken);
		this.myPremium$ = this.store.select(selectMyPremium);
		this.user$ = this.store.select(selectUser);
		this.coupon$ = this.store.select(selectPremiumCouponState);
    this.sessionId$ = this.store.select(selectSessionID);
    this.paymentIntent$ = this.store.select(selectPaymentIntent);
		this.paymentSuccess$ = this.store.select(selectPaymentSuccess);

		this.subList.push(
			this.store
				.select(selectUser)
				.pipe(
					filter((user) => !!user && user.idAnagrafica > 0 && !user?.premium),
					concatLatestFrom(() =>
						this.store
							.select(selectPremiumCurrentContent)
							.pipe(filter((premium) => !!premium && premium === 1))
					)
				)
				.subscribe(([user, _]) => {
          this.userId = user.idAnagrafica;

          this.payloadPremiumModules = {
            forcedCampaigns: 'fdsfs',
            userId: this.userId
          };
          this.store.dispatch(loadPremiumModules(this.payloadPremiumModules));
        })
		);


    this.premiumModules$.subscribe({
    next: (modules) => this.logService.info('Premium modules ha emesso content : ', modules),
    error: (error) => this.logService.info('Premium modules ha emesso content  error: ', error),
    complete: () =>  this.logService.info('Premium modules ha emesso content  COMPLETED: ')
    }
  );


		this.subList.push(
			this.actRoute.paramMap
				.pipe(
					tap((params) => this.logService.info('PARAMS: ', params)),
					// per caricare i dati che servono è necessario mettere in combinazione i paramsMap con authState e template

					combineLatestWith(
						this.store.select(selectUserId).pipe(
							filter((userId) => !!userId),
							// Bug Solved: when redirecting after logout, permission dialog would open without any need
							// Solution: Stop firing loadContent when the user is redirected to another page
							takeUntil(
								this.router.events.pipe(
									combineLatestWith(this.store.select(selectContentTemplate)),
									filter(
										([event, contentTemplates]) =>
											event instanceof NavigationStart &&
											!isContentPage(event.url, contentTemplates)
									)
								)
							)
						),
						this.store.select(selectTemplateByID(this.templateType))
						),
					tap(([paramMap, userId, template]) => {
						// prima di caricare un nuovo contenuto ripulisco il currentContent nello Store e l'eventuale CurrentTopic e anche il poll
						// serve per quando navigo tra un articolo e l'altro
						this.store.dispatch(clearCurrentContent());
						this.store.dispatch(clearCurrentTopic());
            this.store.dispatch(clearPoll());

            const segments = this.actRoute.snapshot.pathFromRoot.flatMap(route => route.url);
            const currentUrl =  '/'+segments.map(segment => segment.path).join('/');

						this.userId = userId;
						this.contentId = Number(paramMap.get('contentId'));
						this.store.dispatch(
							loadContent({
								userId: this.userId,
								contentId: this.contentId,
								template: template,
								callbackUrl: currentUrl
							})
						);
					})
				)
				.subscribe()
		);
	}

	destroy(): void {
		this.subList.forEach((sub) => sub.unsubscribe());

		// ripulisco il currentContent nello Store e l'eventuale CurrentTopic
		this.store.dispatch(clearCurrentContent());
		this.store.dispatch(clearCurrentTopic());
	}

	getErrorMessage(error: ErrorMessage) {
		this.title_message = this.translate.instant('dottnet.shared.error');
		return (
			this.notificationService.getNotificationFromError(error).message +
			' ContentId: ' +
			this.contentId
		);
	}

	analyzeQueryParams<T>(url: string, param: string): T | undefined {
		this.logService.info('URL Router: ', url);
		// Split of router URL in order to analyze query params
		const paramsString: string[] = url.split('?');
		if (paramsString.length > 1) {
			const params: string[] = paramsString[1].split('&');

			for (const value of params) {
				const split = value.split('=');
				if (param === split[0].toLowerCase()) {
					// specifico il tipo di ritorno
					return split[1] as T;
				}
			}
		}
		// Se il parametro non è stato trovato, ritorno undefined
		return undefined;
	}

	// Metodi per extra premium

	checkToken(token: string) {
		this.logService.info('CHECKTOKEN: ', token);
		// se sto passando un token faccio il check, altrimenti ripulisco l'eventuale errore e carico i moduli iniziali
		if (token?.length > 0) {
			this.store.dispatch(checkCodiceSconto({ token: token, userId: this.userId }));
		} else {
			this.store.dispatch(clearErrorToken());
			this.payloadPremiumModules = {
				// TODO: perché fdsfs?
				forcedCampaigns: 'fdsfs',
				userId: this.userId
			};
			this.store.dispatch(loadPremiumModules(this.payloadPremiumModules));
		}
	}

  updateSubscription(updateObject: { module: MyPremiumModuleElement, stripeId: string, userId: number }) {
		this.store.dispatch(updateSubscription({ module: updateObject.module,stripeId: updateObject.stripeId, userId: updateObject.userId  }));
	}

	loadUserPremium(idAnagrafica: number) {
		this.logService.info('Id_anagrafica: ', idAnagrafica);
		if (idAnagrafica > 0) {
			this.store.dispatch(loadUserPremiumAction({ userId: idAnagrafica }));
			this.payloadPremiumModules = {
				forcedCampaigns: 'fdsfs',
				userId: idAnagrafica
			};
			this.store.dispatch(loadPremiumModules(this.payloadPremiumModules));
			// dispatchare la getSconti e passarla a payment-form
			this.store.dispatch(loadDiscountType());
		}
	}

	setAbbonamento(stripeId: string) {
		this.logService.info('Dispatcho la set Abbonamento: ', stripeId);
		// this.store.dispatch(setSubscription({ subscription: premiumSubscription }));

    this.store.dispatch(confirmSubscriptionSuccess({stripeId:stripeId, currentUrl: this.router.url }));
	}

	intentSubscription(subscription: PremiumSubscription) {
		this.logService.info('subscription data : ', subscription);
		this.store.dispatch(intentSubscriptionAction({ subscription: subscription }));
	}

	confirmCardPayment(confirmation: PaymentConfirmation) {
		this.logService.info('confirm Payment : ', confirmation);
		this.store.dispatch(
			confirmCardPaymentAction({ paymentConfirmation: confirmation, currentUrl: this.router.url })
		);
	}


	// Espongo metodo per la get errore del content  - Ritorno un observable di ErrorMessage
	private getContentError(): Observable<ErrorMessage> {
		return this.store.select(selectCurrentError).pipe(filter((responseError) => !!responseError));
	}

	// Save temporary media into store. If file is undefined, clear it
	uploadTemporaryAttachment(file: File) {
		if (file) this.store.dispatch(uploadTemporaryMedia({ media: file }));
		else this.store.dispatch(clearTemporaryMedia());
	}

	submitComment(commentDTO: CreateCommentDTO) {
		this.subList.push(
			this.store
				.select(selectUserId)
				.pipe(
					filter((userId) => !!userId),
					concatLatestFrom(() => [
						this.store
							.select(selectCurrentContent)
							.pipe(filter((currentContent) => !!currentContent)),
						this.store.select(selectTemporaryMediaName)
					]),

					take(1)
				)
				.subscribe(([userId, currentContent, temporaryMediaName]) => {
					const { commentText, attachmentTemplate, parentComment } = commentDTO;

					// If the comment has an attachment
					if (attachmentTemplate && temporaryMediaName) {
						this.store.dispatch(
							insertMediaAndComment({
								// If the comment is a reply to another comment, the latter is the parent.
								// Otherwise, the current content is its parent
								parentId: parentComment ? parentComment.idContenuto : currentContent.idContenuto,
								authorId: userId,
								parentTemplate: getTemplateFromId(
									parentComment ? parentComment.idTemplate : currentContent.idTemplate
								),
								commentTemplate: getThreadTemplate(),
								text: commentText,
								title: 'blank',
								attachmentTemplate,
								fileName: temporaryMediaName
							})
						);
					} else {
						this.store.dispatch(
							insertComment({
								parentId: parentComment ? parentComment.idContenuto : currentContent.idContenuto,
								authorId: userId,
								parentTemplate: getTemplateFromId(
									parentComment ? parentComment.idTemplate : currentContent.idTemplate
								),
								commentTemplate: getThreadTemplate(),
								text: commentText,
								title: 'blank',
								// No attachment present
								attachmentId: undefined,
								attachmentTemplate: undefined
							})
						);
					}
				})
		);
	}

	loadCommentReplies(comment: DNComment) {
		this.subList.push(
			this.store
				.select(selectUserId)
				.pipe(filter((user) => !!user))
				.subscribe((userId) =>
					this.store.dispatch(
						loadComments({
							commentId: comment.idContenuto,
							userId,
							template: getThreadTemplate()
						})
					)
				)
		);
	}

	// Show attachment bound to a commment according to its attachment template
	showAttachment(event: { idAttachment: number; templateAttachment: string }) {
		const { idAttachment, templateAttachment } = event;

		switch (templateAttachment) {
			// commented because pdfjs is not SSR renderadable and this prevented article to be prerendered
			// case 'file':
			// 	this.store.dispatch(
			// 		loadDialog({
			// 			contentId: idAttachment,
			// 			template: templateAttachment,
			// 			componentType: FileReaderDialogComponent
			// 		})
			// 	);
			// 	break;
			case 'gallery':
				this.store.dispatch(
					loadDialog({
						contentId: idAttachment,
						contextUrl:undefined,
						template: templateAttachment,
						componentType: ImageViewerDialogComponent
					})
				);
				break;
			case 'video':
				this.store.dispatch(
					loadDialog({
						contentId: idAttachment,
						contextUrl:undefined,
						template: templateAttachment,
						componentType: VideoPlayerDialogComponent
					})
				);
				break;
			default:
				break;
		}
	}
}
