import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { EnvironmentService } from '../../../environments/environment.service';
import { faFacebook, faWhatsapp, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { filter, Observable, tap } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'dottnet-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        style: 'width: 100%'
    },
    standalone: true,
    imports: [FontAwesomeModule]
})
export class SocialComponent implements OnChanges {
	@Input() url: string;
	shareLink: string;
	// Icons
	facebook = faFacebook;
	whatsapp = faWhatsapp;
	twitter = faTwitter;
	linkedin = faLinkedin;

	constructor(private environmentService: EnvironmentService) {}

	ngOnChanges() {
		this.shareLink = this.environmentService.clientDomain + this.url;
	}
}
