<div class="flex-row flex-align-center social">
    <a target="_blank" href="https://www.facebook.com/share.php?u={{ shareLink }}">
        <fa-icon class="fa-3x facebook" [icon]="facebook"></fa-icon>
    </a>

    <a target="_blank" href="https://twitter.com/share?url={{ shareLink }}">
        <!-- <fa-icon class="fa-3x twitter" [icon]="twitter"></fa-icon> -->
        <img src="/assets/twitter-mobile.png" width="40" />
    </a>

    <a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url={{ shareLink }}">
        <fa-icon class="fa-3x linkedin" [icon]="linkedin"></fa-icon>
    </a>
    <a target="_blank" href="https://api.whatsapp.com://send?text={{ shareLink }}" data-action="share/whatsapp/share">
        <fa-icon class="fa-3x whatsapp" [icon]="whatsapp"></fa-icon>
    </a>
</div>
